"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { CookieIcon } from "@radix-ui/react-icons";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "../ui/button";

type CookieChoice = "accepted" | "declined";

const CookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useState<CookieChoice>("accepted");

  const handleAccept = () => {
    localStorage.setItem("cookie-consent", "accepted");
    setCookieConsent("accepted");
  };

  const handleDecline = () => {
    localStorage.setItem("cookie-consent", "declined");
    setCookieConsent("declined");
  };

  useEffect(() => {
    setCookieConsent(localStorage.getItem("cookie-consent") as CookieChoice);
  }, []);

  return cookieConsent ? null : (
    <Alert className="fixed bottom-0 left-0 m-2 p-4 space-x-3 w-fit max-w-sm z-50">
      <CookieIcon className="h-6 w-6" />
      <AlertDescription className="mb-2">
        We use cookies to improve user experience and analyze website traffic.
        By clicking &quot;Accept&quot;, you consent to our use of cookies. You
        can also choose to &quot;Decline&quot; or continue using the site with
        the banner open. For more information, please read our{" "}
        <Link
          className="hover:text-elevated-300 hover:underline"
          href="/privacy"
        >
          Privacy Policy.
        </Link>
      </AlertDescription>
      <AlertDescription className="flex space-x-2 justify-end">
        <Button onClick={handleDecline} variant="ghost">
          Decline
        </Button>
        <Button onClick={handleAccept} variant="default">
          Accept
        </Button>
      </AlertDescription>
    </Alert>
  );
};

export default CookieConsent;
