
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"06d4d3105f6e172158021ad862e82fbd7e11f37d":"handleLogin","dea5bdde5af444fa839f529ff208b1598d70ec45":"handleLogout"} */ export var handleLogout = createServerReference("dea5bdde5af444fa839f529ff208b1598d70ec45");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var handleLogin = createServerReference("06d4d3105f6e172158021ad862e82fbd7e11f37d");

