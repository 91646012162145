"use client";

import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { Session } from "next-auth";
import { useInView } from "react-intersection-observer";
import {
  ActivityLogIcon,
  ArrowTopRightIcon,
  ChatBubbleIcon,
  HeartIcon,
  HomeIcon,
  LockClosedIcon,
  PlusIcon,
} from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";

import { handleLogin, handleLogout } from "./action";
import { MenuLink } from "./menu-link";

const ThemeToggle = dynamic(() => import("./theme-toggle"), { ssr: false });
const Logo = dynamic(() => import("../logo"), { ssr: false });

interface Props {
  session: Session | null;
}

const Navbar = ({ session }: Props) => {
  const [active, setActive] = useState(false);

  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: "-66px",
  });

  useEffect(() => {
    const delay = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setActive(true);
    };

    delay();
  }, [inView]);

  async function handleAuthClick() {
    if (session?.user) {
      await handleLogout();
    } else {
      await handleLogin();
    }
  }

  return (
    <>
      <div
        className={cn(
          "fixed z-10 top-0 left-0 w-full p-4 flex flex-row justify-between fill-current text-foreground bg-background transition-shadow duration-300 ease-in-out",
          active && !inView ? "shadow-lg" : "shadow-none"
        )}
      >
        <Link href="/" id="home-link" title="Home" className="w-fit block">
          <Logo />
        </Link>

        <div className="space-x-2">
          <Sheet>
            <ThemeToggle />
            <SheetTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                id="nav"
                title="Open links menu"
              >
                <PlusIcon />
              </Button>
            </SheetTrigger>

            <SheetContent className="flex flex-col gap-4">
              <SheetHeader className="text-left">
                <SheetTitle>Links</SheetTitle>
                <SheetDescription>
                  You can interact with the whole site using the chat interface.
                </SheetDescription>
              </SheetHeader>

              <div className="flex flex-col gap-4 my-8">
                <MenuLink href="/">
                  <HomeIcon />
                  Home
                </MenuLink>

                <MenuLink href="/chat">
                  <ChatBubbleIcon />
                  AI Chat
                </MenuLink>

                <MenuLink href="/articles">
                  <ActivityLogIcon />
                  Projects
                </MenuLink>

                <MenuLink href="/contact" disabled>
                  <HeartIcon />
                  Contact
                </MenuLink>
              </div>

              <SheetFooter className="flex flex-col justify-end grow sm:flex-col">
                <div className="flex flex-row items-end justify-between">
                  <div className="flex flex-col gap-4">
                    <MenuLink href="terms-of-service">Terms</MenuLink>
                    <MenuLink href="privacy">Privacy</MenuLink>
                    <MenuLink href="imprint">Imprint</MenuLink>
                    <MenuLink
                      href="https://github.com/schlomoh"
                      target="_blank"
                    >
                      Github Profile
                      <ArrowTopRightIcon />
                    </MenuLink>
                  </div>

                  <Button
                    id="auth-button"
                    title="Private admin access"
                    onClick={handleAuthClick}
                    variant="ghost"
                    className="w-fit gap-2 flex"
                  >
                    {session?.user ? "Logout" : ""}
                    <LockClosedIcon />
                  </Button>
                </div>
              </SheetFooter>
            </SheetContent>
          </Sheet>
        </div>
      </div>
      <div id="nav-offset" ref={ref} className="m-navbar" />
    </>
  );
};

export default Navbar;
