import { ComponentProps } from "react";
import Link from "next/link";

import { Button } from "../ui/button";
import { SheetClose } from "../ui/sheet";

export const MenuLink = ({
  href,
  disabled = false,
  target = "_self",
  ...props
}: ComponentProps<"button"> & ComponentProps<typeof Link>) => {
  const content = (
    <span>
      <Button
        variant="ghost"
        className="flex gap-4 items-center"
        disabled={disabled}
        id="menu-button"
        title={href.toString().split("/").at(-1)}
        {...props}
      />
    </span>
  );

  return disabled ? (
    content
  ) : (
    <SheetClose asChild>
      <Link href={href} target={target}>
        {content}
      </Link>
    </SheetClose>
  );
};
